.controller-wrap {
  position: fixed;
  bottom: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.MuiPaper-root.controller {
  display: flex;
  border-radius: 20px;
  align-items: center;
  padding: 10px;
  background-color: #000D;
  hr {
    margin: 0 5px;
  }
  .speed-slider {
    width: 100px;
    margin: 0 20px;
    .MuiSlider-markLabel {
      top: calc(100% - .5rem);
    }
  }
  
}