$label-bg: rgb(222, 243, 253);
$compare-bg: #FFF;
$compare-border: 2px solid #AAA;
$compare-line: #333;

.stage {
  flex-shrink: 0;
  position: relative;
}
.stage-static {
  display: flex;
  flex-direction: column;
  align-items: center;
  &.logged {
    flex-direction: row;
    align-items: stretch;
    > .stage-static {
      flex-grow: 1;
    }
  }
  .inline-variables {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
}
.stage-animation {
  position: absolute;
  top: 0;
  left: 0;
  > .number {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
  }
  > .number.null {
    display: none;
  }
  > .indexer {
    z-index: 20;
  }
}
.stage-logger {
  width: 260px;
  flex-shrink: 0;
  margin: 0 20px;
  > div {
    position: fixed;
    z-index: 100;
  }
}

.compare-line {
  position: absolute;
  filter: opacity(0);
  height: 2px;
  transform-origin: 1px;
  background-image: repeating-linear-gradient(to right, $compare-line, $compare-line 5px, $label-bg 5px, $label-bg 10px);
  box-shadow: 0 0 2px 4px $label-bg;
  z-index: 5;
}
.compare-symbol {
  position: absolute;
  filter: opacity(0);
  transform-origin: center;
  z-index: 15;
  > div {
    height: 0;
    padding-bottom: 100%;
    background-repeat: no-repeat;
    background-size: contain;
  }
}
.symbol-eq {
  background-image: url(eq.png);
}
.symbol-lt {
  background-image: url(lt.png);
}
.symbol-gt {
  background-image: url(gt.png);
}

.variable {
  border-radius: 16px;
  background-color: $label-bg;
  position: relative;
  filter: drop-shadow(0px 2px 2px #0003);
  flex-shrink: 0;
  max-width: calc(100vw - 80px);
  &.inline {
    padding-left: 5px;
    margin-top: 10px;
    align-self: flex-start;
    display: flex;
    align-items: center;
  }
  &:not(.inline) {
    padding: 10px;
    margin-top: 40px;
    border-top-left-radius: 0;
    > .variable-label {
      position: absolute;
      top: -30px;
      left: 0;
      height: 30px;
      line-height: 30px;
      padding: 0 10px;
      display: inline-block;
      &::before {
        content: "";
        background-color: $label-bg;
        width: calc(100% + 30px);
        height: calc(100% + 20px);
        margin: -10px;
        margin-top: -20px;
        margin-bottom: -30px;
        display: block;
        border-radius: 16px 16px 0 0;

        transform-origin: left bottom;
        transform: perspective(200px) rotateX(45deg);
      }
      .label-text {
        position: relative;
      }
    }
  }
}
.label-text {
  font-family: 'Courier New', Courier, monospace;
  font-weight: bold;
}