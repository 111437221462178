$stripe-1: #8884;
$stripe-2: #8880;
$stripe-width: 8px;

.number {
  height: 60px;
  min-width: 60px;
  border-radius: 12px;
  background-image: repeating-linear-gradient(45deg, $stripe-1, $stripe-1 $stripe-width, $stripe-2 $stripe-width, $stripe-2 $stripe-width * 2);
  position: relative;

  .content {
    padding: 0 10px;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(0, 132, 209);
    border-radius: 12px;
    color: white;
    font-size: 30px;
    transition-property: background-color;
    transition-timing-function: ease-out;
    &.highlight {
      background-color: rgb(0, 177, 80);
    }
    .weaken {
      color: lighten(rgb(0, 132, 209), 20%);
    }
  }
  &.null {
    .content {
      visibility: hidden;
    }
  }
  &.hide:not(.null) {
    filter: opacity(.3);
  }
  .link {
    position: absolute;
    width: 35px;
    top: 16px;
    right: -30px;
    color:rgb(0, 132, 209);
    filter: opacity(0);
    transform: translateX(-20px);
    transition-property: filter, transform;
    transition-timing-function: ease-out;
    &.show {
      filter: opacity(1);
      transform: translateX(0);
    }
  }
}