.bucket {
  display: flex;
  align-self: flex-start;
  min-height: 70px;
  margin-bottom: 5px;
  .label {
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-right: 2px solid rgb(159, 225, 255);
    margin-right: 10px;
    width: 30px;
    text-align: right;
  }
  .numbers {
    display: flex;
    flex-wrap: wrap;
  }
}
.bucket-item {
  margin: 5px 0;
  margin-right: 20px;
  > .hide,
  > .null {
    visibility: hidden;
  }
}